import React from "react"
import { Image } from "../../types"
import Cadre from "../Cadre/Cadre"

import "./VideoSection.less"

interface MenuProps {
  video?: Image
  poster: Image
}

export default function VideoSection({ video, poster }: MenuProps) {
  return (
    <div className="video">
      <div className="title-video" style={{ flex: 1 }}>
        <span>Regardez la vidéo de présentation</span>
        <h2>BEST BURGER IN TOWN</h2>
      </div>
      <Cadre
        color="#E6A133"
        widthX={4}
        widthY={4}
        ratioX={0.5}
        ratioY={0.5}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={1}
        styleContent={{ padding: "calc(4vw + 2px) calc(2vw + 2px)" }}
      >
        <video
          width="100%"
          poster={poster.file.url}
          preload="metadata"
          controls
        >
          <source src={video.file.url} type="video/mp4"></source>
        </video>
      </Cadre>
    </div>
  )
}
