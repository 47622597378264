import { Link } from "gatsby"
import React from "react"
import { LivraisonItem } from "../../types"
import Img from "gatsby-image"

interface LivraisonItemProps {
  livraisonItem: LivraisonItem
}

export default function LivraisonItemElement({
  livraisonItem,
}: LivraisonItemProps) {
  return (
    <Link to={livraisonItem.url} target="_blank">
      <div className="image-container">
        <div className="image-not-hover image">
          <Img
            fluid={livraisonItem.logo.fluid}
            alt={livraisonItem.logo.description}
          />
        </div>
        <div className="image-hover image">
          <Img
            fluid={livraisonItem.logoHover.fluid}
            alt={livraisonItem.logoHover.description}
          />
        </div>
      </div>
    </Link>
  )
}
