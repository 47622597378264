import React from "react"
import { Image } from "../../types"
import Cadre from "../Cadre/Cadre"
import Img from "gatsby-image"

interface ImageCadreProps {
  image?: Image
}

export default function ImageCadre({ image }: ImageCadreProps) {
  return (
    <Cadre
      color="#E6A133"
      widthX={2}
      widthY={4}
      ratioX={0.5}
      ratioY={0.5}
      paddingContent={0.5}
      widthLargeLine={2}
      widthLightLine={1}
      style={{ flex: 1 }}
    >
      {image !== undefined && (
        <div style={{ padding: "1em 2em" }}>
          <Img
            fluid={image.fluid}
            alt={image.description}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </Cadre>
  )
}
