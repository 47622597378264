import React, { useState } from "react"
import Img from "gatsby-image"

import { Album } from "../../types"

import "./AlbumSection.less"

interface AlbumProps {
  album: Album
}
export default function AlbumSection({ album }: AlbumProps) {
  const [expanded, setExpanded] = useState<boolean>(false)

  const expandAlbum = () => {
    setExpanded(true)
  }
  return (
    <div className="album">
      {album.lines.map((line, index) => (
        <div key={`line-${index}`} className="line">
          {line.items.map(item => (
            <div
              className={
                item.mobileVisible || expanded
                  ? "album-item"
                  : "album-item not-mobile"
              }
              key={item.contentful_id}
            >
              <Img
                fluid={item.image.fluid}
                alt={item.image.description}
                key={item.image.contentful_id}
              />
            </div>
          ))}
        </div>
      ))}
      {!expanded && (
        <button className="btn" onClick={expandAlbum}>
          Voir plus de photos
        </button>
      )}
    </div>
  )
}
