import React from "react"
import { graphql } from "gatsby"

import {
  Album,
  HorairesOuverture,
  Image,
  Livraison,
  SeoContent,
} from "../types"
import Seo from "../components/Seo"
import Header from "../components/Header/Header"
import ImageCadre from "../components/ImageCadre/ImageCadre"
import Adresse from "../components/Adresse/Adresse"
import Horaires from "../components/Horaires/Horaires"
import { findAssetById, findLivraisonById, getUsedHoraires } from "../utils"
import APropos from "../components/APropos/APropos"
import AlbumSection from "../components/AlbumSection/AlbumSection"
import Menu from "../components/Menu/Menu"
import VideoSection from "../components/VideoSection/ViodeoSection"
import LivraisonSection from "../components/LivraisonSection/LivraisonSection"

import "./index.less"
import Footer from "../components/Footer/Footer"

interface QueryIndex {
  allContentfulHorairesOuverture: {
    edges: { node: HorairesOuverture }[]
  }
  allContentfulAsset: {
    edges: { node: Image }[]
  }
  contentfulSeo: SeoContent
  contentfulAlbum: Album

  allContentfulLivraison: {
    edges: { node: Livraison }[]
  }
}

interface HomeProps {
  data: QueryIndex
}

export default function Home({ data }: HomeProps) {
  const images: Image[] = data.allContentfulAsset.edges
    .map(itemNode => itemNode.node)
    .reduce((acc, current) => {
      const x = acc.find(item => item.contentful_id === current.contentful_id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])

  const livraison = findLivraisonById(
    data.allContentfulLivraison.edges.map(itemNode => itemNode.node),
    process.env.GATSBY_LIVRAISON
  )

  const imageMenu = findAssetById(images, process.env.GATSBY_IMAGE_MENU)
  const logo = findAssetById(images, process.env.GATSBY_LOGO_HEADER)

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div className="main-page">
      <Seo data={data.contentfulSeo} />
      <Header logo={logo} menu={imageMenu} />
      <APropos />
      <div className="description">
        <div style={{ flex: 1 }} className="mobile-hide">
          <ImageCadre
            image={findAssetById(images, process.env.GATSBY_IMAGE_LEFT_ADRESSE)}
          />
        </div>
        <div className="horaire-adresse">
          <Adresse />
          <Horaires
            horaires={getUsedHoraires(
              data.allContentfulHorairesOuverture.edges
            )}
          />
        </div>
        <div style={{ flex: 1 }} className="mobile-hide">
          <ImageCadre
            image={findAssetById(
              images,
              process.env.GATSBY_IMAGE_RIGHT_ADRESSE
            )}
          />
        </div>
      </div>
      {data.contentfulAlbum !== undefined && (
        <AlbumSection album={data.contentfulAlbum} />
      )}
      <Menu image={imageMenu} />
      <LivraisonSection livraison={livraison} />
      <VideoSection
        video={findAssetById(images, process.env.GATSBY_VIDEO)}
        poster={findAssetById(images, process.env.GATSBY_VIDEO_POSTER)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulHorairesOuverture {
      edges {
        node {
          title
          contentful_id
          creneaux {
            contentful_id
            dayName
            day
            dejeunerClosed
            dejeunerEnd
            dejeunerStart
            dinerClosed
            dinerStart
            dinerEnd
          }
        }
      }
    }
    contentfulAlbum {
      contentful_id
      lines {
        contentful_id
        items {
          contentful_id
          mobileVisible
          image {
            description
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
        }
      }
    }
    allContentfulLivraison {
      edges {
        node {
          contentful_id
          logos {
            contentful_id
            url
            logo {
              contentful_id
              description
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid
              }
              file {
                url
              }
            }
            logoHover {
              contentful_id
              description
              fluid(maxWidth: 980) {
                ...GatsbyContentfulFluid
              }
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulAsset {
      edges {
        node {
          contentful_id
          description
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
      }
    }
    contentfulSeo {
      title
      childContentfulSeoDescriptionTextNode {
        description
      }
      childContentfulSeoKeywordsTextNode {
        keywords
      }
      ogtitle
      ogsiteName
      ogdescription {
        ogdescription
      }
      ogimage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        description
      }
      twittercard
      twittertitle
      childContentfulSeoTwitterdescriptionTextNode {
        twitterdescription
      }
      twitterimage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        description
      }
    }
  }
`
