import React from "react"
import instagramIcon from "@iconify/icons-fa/instagram"
import facebookIcon from "@iconify/icons-fa/facebook"
import tripadvisorIcon from "@iconify/icons-fa/tripadvisor"
import pinterestIcon from "@iconify/icons-fa/pinterest"

import "./Footer.less"
import { Link } from "gatsby"
import Icon from "@iconify/react"

export default function Footer() {
  return (
    <footer>
      <div className="contact">
        <a href="mailto:contact@mobsterdiner.com">contact@mobsterdiner.com</a>
        <a href="tel:+33981421687">09 81 42 16 87</a>
      </div>
      <div className="center">
        <h3>MOBSTER DINER</h3>
        <div className="footerSocials">
          <Link
            to="https://www.instagram.com/mobsterdiner/?hl=fr"
            target="_blank"
          >
            <Icon icon={instagramIcon} />
          </Link>
          <Link to="https://www.facebook.com/mobsterdiner/" target="_blank">
            <Icon icon={facebookIcon} />
          </Link>
          <Link
            to="https://www.tripadvisor.fr/Restaurant_Review-g187147-d6632258-Reviews-Mobster_Diner-Paris_Ile_de_France.html"
            target="_blank"
          >
            <Icon icon={tripadvisorIcon} />
          </Link>
          <Link to="https://www.pinterest.fr/MobsterDiner" target="_blank">
            <Icon icon={pinterestIcon} />
          </Link>
        </div>
      </div>
      <div className="copyright">
        <span>© Mobster Diner 2014 - 2020</span>
        <span>Tous droits réservés</span>
      </div>
    </footer>
  )
}
