import { Link } from "gatsby"
import React from "react"
import { Image } from "../../types"

import "./Menu.less"

interface MenuProps {
  image?: Image
}

export default function Menu({ image }: MenuProps) {
  return (
    <div className="menu">
      <Link to="/menu/" className="btn" target="_blank">
        Découvrir le menu
      </Link>
    </div>
  )
}
