import React from "react"
import { Livraison } from "../../types"
import Cadre from "../Cadre/Cadre"
import LivraisonItemElement from "./LivraisonItem"

import "./LivraisonSection.less"

interface LivraisonProps {
  livraison: Livraison
}

export default function LivraisonSection({ livraison }: LivraisonProps) {
  return (
    <div
      className="livraison"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      id="livraison"
    >
      <Cadre
        color="#E6A133"
        widthX={3}
        widthY={1}
        ratioX={0.5}
        ratioY={0.4}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={2}
        displayDivider={false}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: "1em 2em",
        }}
      >
        <span className="subtitle">Et si vous faisiez livrer ?</span>
        <h2>MOBSTER DINER</h2>
      </Cadre>
      <div className="livraison-items" style={{ width: "100%" }}>
        {livraison.logos.map(item => (
          <LivraisonItemElement key={item.contentful_id} livraisonItem={item} />
        ))}
      </div>
    </div>
  )
}
