import React from "react"
import Cadre from "../Cadre/Cadre"

import "./APropos.less"

export default function APropos() {
  return (
    <div className="a-propos">
      <Cadre
        color="#E6A133"
        widthX={3}
        widthY={1}
        ratioX={0.6}
        ratioY={0.3}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={1}
        displayDivider={false}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <h2>A propos</h2>
      </Cadre>
      <p>
        Mobster Diner, dont on dit qu’il est l’un des meilleurs burgers de
        Paris, a été créé en 2014 avec un état d'esprit : proposer des burgers
        de grande qualité à un prix imbattable. Grands amateurs de jazz, les
        fondateurs ont créé un cadre convivial de dégustation reflétant au mieux
        l'époque la plus représentative de ce genre musical : les années 1920 et
        la prohibition. Le restaurant est ouvert 7j/7, midi et soir dans le 5ème
        arrondissement de Paris, près de Jussieu, Cardinal Lemoine, place Monge,
        rue Mouffetard, panthéon, quartier latin et du jardin des plantes. Des
        versions végétariennes sont possibles pour tous les burgers proposés.
        Vente sur place, à emporter ou en livraison.
      </p>
    </div>
  )
}
